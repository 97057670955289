import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import NavbarLocation from "../../Navbar/NavbarLocation";
import "./DrNBCourses.css";
import neurologyImg from "../../../assets/Neurology DrNB Details.jpg";
import SEO from "../../SEO/SEO";
import emailjs from "@emailjs/browser";
import Img1 from "../../../assets/doctors/maya_gantayet.png";
import Img2 from "../../../assets/doctors/geeta_mohanty.jpg";
import Img3 from "../../../assets/doctors/manoranjan.jpg";
import Img4 from "../../../assets/doctors/Soumya_Pradhan.png";
import Img5 from "../../../assets/doctors/Binay_ku_Jaiswal.jpg";
import Img6 from "../../../assets/doctors/Dr.Benudhar-Lenka.png";
import Img7 from "../../../assets/doctors/Dr.Debabrata-Tadu.png";
import Img8 from "../../../assets/doctors/rashmi_ranjan_satpathy.png";
import Img9 from "../../../assets/doctors/nimisha_padhi.jpg";
import Img10 from "../../../assets/doctors/manisha_mishra.png";
import Img11 from "../../../assets/doctors/satya_prakash_mohanty.png";
import Img12 from "../../../assets/doctors/bithika_jana.png";
import Img13 from "../../../assets/doctors/swaleha.png";
import Img14 from "../../../assets/doctors/raksha-baheti.png";
import Img15 from "../../../assets/doctors/Dr.Sunita-Dutta.png";
import Img16 from "../../../assets/department photos/reception.jpg";
import Img17 from "../../../assets/department photos/emergency1.jpg";
import Img18 from "../../../assets/department photos/neuro-opd.jpg";
import Img19 from "../../../assets/department photos/criticalcare4.jpg";
import Img20 from "../../../assets/department photos/criticalcare2.jpg";
import Img21 from "../../../assets/department photos/generalsurgery1.png";
import Img22 from "../../../assets/department photos/neuro19.jpg";
import Img23 from "../../../assets/department photos/MRI Machine.jpg";
import Img24 from "../../../assets/department photos/CT Scan.jpg";
import Img25 from "../../../assets/department photos/neuro5.png";
import Img26 from "../../../assets/doctors/Dr.Dibyajit-Sabath.png";
import Img27 from "../../../assets/doctors/abhinas_das.png";

function DrNBNeurology() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.toLowerCase();

  let locationFilter = null;
  let basePath = "/Neurology";
  if (path.includes("ashwinihospitalcuttack")) {
    locationFilter = "Ashwini Hospital";
    basePath = "/AshwiniHospitalCuttack/Neurology";
  } else if (path.includes("adityaashwinihospital")) {
    locationFilter = "Aditya Ashwini Hospital";
  } else if (path.includes("ashwinitraumacentre")) {
    locationFilter = "Ashwini Trauma Centre";
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_tj5tyam",
        "template_1tpu44e",
        formData,
        "egxcYC6EeJdXDm0et"
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response);
          setStatus("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send email:", error);
          setStatus("Failed to send message. Please try again.");
        }
      );
  };
  const services = [
    {
      image: Img16,
      name: "Reception",
    },
    {
      image: Img17,
      name: "Emergency",
    },
    {
      image: Img18,
      name: "OPD ",
    },
    {
      image: Img19,
      name: "CCU",
    },
    {
      image: Img20,
      name: "ICU",
    },
    {
      image: Img21,
      name: "Operation Theatre",
    },
    {
      image: Img22,
      name: "Neuro Cath Lab",
    },
    {
      image: Img23,
      name: "MRI Machine",
    },
    {
      image: Img24,
      name: "CT Scan",
    },
    {
      image: Img25,
      name: "EEG",
    },
  ];
  const slides = [
    {
      image: Img1,
      name: "Dr. Maya Gantayet",
      qualification: "DM(Neurology), Faculty (DrNB Neurology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img2,
      name: "Dr. Geeta Mohanty",
      qualification: "DM(Neurology), Faculty (DrNB Neurology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img3,
      name: "Dr. Manoranjan Acharya",
      qualification: "DM(Neurology), Faculty (DrNB Neurology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img4,
      name: "Dr. Soumya Ranjan Pradhan",
      qualification: "DM(Neurology), Faculty (DrNB Neurology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img5,
      name: "Dr. Binay Kumar Jaiswal",
      qualification: "DM(Neurology)",
      location: "Ashwini Hospital",
    },
    {
      image: Img6,
      name: "Dr. Benudhar Lenka",
      qualification: "M.Ch(Neurosurgery),Sr. Faculty",
      location: "Ashwini Hospital",
    },
    {
      image: Img7,
      name: "Dr. Debabrata Tadu",
      qualification: "M.Ch(Neurosurgery)",
      location: "Ashwini Hospital",
    },
    {
      image: Img8,
      name: "Dr. Rashmi Ranjan Satapathy",
      qualification: "DNB(Anesthesia)",
      location: "Ashwini Hospital",
    },
    {
      image: Img9,
      name: "Dr. Nimisha Padhi",
      qualification: "MD(Anesthesia)",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img10,
      name: "Dr. Manisha Mishra",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img11,
      name: "Dr. Satya Prakash Mohanty",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img27,
      name: "Dr. Abhinas Das",
      qualification: "Physiotherapist",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img26,
      name: "Dr. Dibyajit Sabath",
      qualification: "Physiotherapist",
      location: "Ashwini Hospital",
    },
    {
      image: Img12,
      name: "Dt. Bithika Jana",
      qualification: "M.Sc(Nutrition & Dietetics)",
      location: "Ashwini Hospital",
    },
    {
      image: Img13,
      name: "Dt. Swaleha Shirin",
      qualification: "Nutrition & Dietetics",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img14,
      name: "Dt. Raksha Baheti",
      qualification: "Nutrition & Dietetics",
      location: "Aditya Ashwini Hospital",
    },
    {
      image: Img15,
      name: "Dr. Sunita Dutta",
      qualification: "Speech Therapist",
      location: "Ashwini Hospital",
    },
  ];
  const filteredDoctors = locationFilter
    ? slides.filter((doc) => doc.location === locationFilter)
    : slides;

  return (
    <>
      {locationFilter ? <NavbarLocation /> : <Navbar />}
      <SEO
        title=" Advance Your Career with DrNB Neurology | Ashwini Hospital – Apply Now"
        description="Join the DrNB Neurology program at Ashwini Hospital, Odisha, accredited by NBEMS. Get advanced neurology training with expert faculty, modern facilities, and hands-on experience. Apply today!"
        keywords="Super-Specialty Neurology Course,Best Institute for DrNB Neurology,DrNB Neurology training in India,Best DrNB Neurology courses in Odisha,NBE accredited DrNB Neurology program,Top DrNB hospitals in Odisha,DrNB Neurology admission in India,Best DrNB programs in Odisha,Post Fellowship training in Odisha,NBE approved medical institutes in India,Best hospital for DrNB courses,DrNB Neurology Program Details"
      />
      <div className="drnb-neurology">
        {/* Banner Section */}
        <button
          className="btn btn-danger position-absolute"
          onClick={() => navigate(basePath)}
        >
          Neurology Department
        </button>
        <section className="banner">
          <h1>DrNB NEUROLOGY</h1>
          <p>
            Accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences, New Delhi
            </strong>
            , Ashwini Group of Hospitals proudly offers{" "}
            <span className="text-warning fw-bold">3 Seats</span> for Post
            Graduate training in Neurology.
          </p>
        </section>

        {/* Description */}
        <section className="drnb-description mt-2">
          <p>
            Ashwini Group of Hospitals proudly offers the{" "}
            <strong>DrNB Neurology</strong> program, accredited by the{" "}
            <strong>
              National Board of Examinations in Medical Sciences (NBEMS), New
              Delhi
            </strong>
            . Our hospital is recognized as a leading healthcare institution in
            Odisha, offering state-of-the-art medical training and advanced
            neurological care. The DrNB Neurology program is designed for
            postgraduate medical professionals who wish to specialize in
            neurology, providing them with hands-on clinical experience,
            research opportunities, and expert mentorship.
          </p>
        </section>
        <section className="about-hospital mt-2">
          <h2 className="sec-title">About Ashwini Hospital</h2>
          <p>
            <strong>Ashwini Hospital</strong> is one of the top{" "}
            <strong>Multispecialty Hospitals in Odisha</strong>, renowned for
            its cutting-edge technology, experienced faculty, and
            patient-centered care. With a bed capacity of over{" "}
            <strong>500+</strong> across multiple units, the hospital offers
            comprehensive Neurology services. Our fully-equipped neuro labs,
            advanced imaging facilities, and dedicated ICU & emergency care
            units provide an ideal environment for specialized training.
          </p>
        </section>
        {/* Admission Procedure */}
        <section className="admission">
          <h2 className="sec-title">Admission Procedure</h2>
          {/* How to Apply */}
          <section className="apply">
            <h5 className="text-decoration-underline text-primary">
              How to Apply
            </h5>
            <p>
              Candidates interested in enrolling for the DrNB Neurology program
              must qualify for the{" "}
              <strong>
                NEET-SS (National Eligibility cum Entrance Test – Super
                Specialty), conducted by NBEMS
              </strong>
              . Admission is based on NEET-SS rank, followed by the NBEMS
              counseling process. Shortlisted candidates will be guided through
              the documentation and admission formalities.
            </p>
          </section>
          <ul className="list-unstyled">
            <li>
              ✔️ <strong className="text-success">Step 1:</strong> Appear for{" "}
              <strong>NEET-SS</strong> conducted by NBEMS and obtain a
              Qualifying Rank.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 2:</strong> Choose
              Ashwini Hospital for <strong>DrNB Neurology</strong> during the{" "}
              <b>Counseling Process.</b>
            </li>
            <li>
              ✔️ <strong className="text-success">Step 3:</strong> Complete the
              required document verification & formalities.
            </li>
            <li>
              ✔️ <strong className="text-success">Step 4:</strong> Admission
              confirmation and course commencement.
            </li>
          </ul>
          <p className="lh-1">
            <span className="text-title">
              &#128204;For Admissions, refer to:{" "}
              <a
                href="https://nbe.edu.in"
                target="_blank"
                className="text-primary text-decoration-none"
                rel="noopener noreferrer"
              >
                NBEMS Official Website
              </a>
            </span>
          </p>
        </section>
        {/* Course Duration */}
        <section className="course-duration">
          <h5 className="text-decoration-underline text-primary">
            Course Duration
          </h5>
          <p>
            The DrNB Neurology program spans Three years <b>(3 Years)</b>,
            focusing on advanced Neurological Diagnosis, treatment protocols,
            Neurophysiology, and Interventional Neurology.
          </p>
        </section>
        {/* Eligibility Criteria */}
        <section className="eligibility">
          <h5 className="text-decoration-underline text-primary">
            Eligibility Criteria
          </h5>
          <p>
            Any medical graduate with an{" "}
            <strong>MD/DNB in General Medicine or Pediatrics</strong> who has
            qualified the <strong>Entrance Examination</strong> conducted by
            NBEMS and meets the eligibility criteria for admission to{" "}
            <strong>DrNB Super Specialty</strong> courses at NBEMS-accredited
            medical colleges, institutions, or hospitals across India is
            eligible to participate in the centralized counseling process.{" "}
            <strong>
              Seat allocation for DrNB Neurology is done purely on a
              merit-cum-choice basis.
            </strong>
          </p>
        </section>

        {/* Opportunities Section */}
        <section className="opportunities">
          <h2 className="sec-title">Opportunities</h2>
          <p>
            Candidates who obtain the <strong>DrNB Qualification</strong> after
            successfully completing the DrNB examination are eligible for{" "}
            <strong>specialist positions</strong> in hospitals as well as{" "}
            <strong>faculty roles in teaching institutions.</strong> They can be
            appointed as{" "}
            <strong>
              faculty members, teachers, assistant professors, or lecturers in
              medical training institutions.
            </strong>
            The accreditation of institutes and the selection of residents are
            conducted strictly in accordance with the{" "}
            <strong>guidelines and regulations set by NBEMS</strong>, which are
            updated periodically.
          </p>
        </section>

        {/* Registration Form */}
        <section className="registration d-flex justify-content-center">
          {/* Image Section */}
          <div className="promo-image">
            <img
              src={neurologyImg}
              alt="DrNB Neurology Program"
              title="DrNB Neurology Program Details"
              loading="lazy"
            />
          </div>
          <div className="admission-form-container mt-4">
            <h4 className="sec-title">Enroll in DrNB Neurology Program</h4>
            {status && <p className="status-message">{status}</p>}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Phone</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Your Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  placeholder="Your Message Here"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </section>

        {/* Neurology Team */}
        <section className="team">
          <h2 className="sec-title">Our Neuro Sciences Team</h2>
          <div className="row row-cols-1 row-cols-md-4 g-4 ">
            {filteredDoctors.length > 0
              ? filteredDoctors.map((doctor, index) => (
                  <div className="col-lg-2 col-md-4" key={index}>
                    <a
                      className="doc-name"
                      href={`/Doctors/${doctor.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}`}
                    >
                      <div className="card h-100 doctor_img my-doc">
                        <img
                          src={doctor.image}
                          alt={`${doctor.name} Img`}
                          title={doctor.name}
                        />
                        <div className="drnb-doc-text mt-2">
                          <h3>{doctor.name}</h3>
                          <p>{doctor.qualification}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              : ""}
          </div>
        </section>

        {/* Services & Facilities */}
        <section className="facilities">
          <h2 className="sec-title">Our Services & Facilities</h2>
          <div className="row row-cols-1 row-cols-md-4 g-4 mt-1  ">
            {services.map((slide, index) => (
              <div className="services col-lg-3 col-md-4 " key={index}>
                <div className="card h-100 ">
                  <img
                    src={slide.image}
                    alt={`${slide.name} Img`}
                    title={slide.name}
                  />
                  <div className="mt-2">
                    <h4 className="fs-6 text-primary">{slide.name}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
export default DrNBNeurology;
