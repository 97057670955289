import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import NephrologyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/alok_kumar_mohapatra.png";
import G1 from "../../assets/department photos/nephrology1.png";
import G2 from "../../assets/department photos/nephrology2.png";
import G3 from "../../assets/department photos/nephrology3.png";

function Nephrology() {
  const [openIndex, setOpenIndex] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
   const [currentCaption, setCurrentCaption] = useState("");
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const openModal = (image, caption) => {
    setCurrentImage(image);
    setCurrentCaption(caption);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };
  const slides = [
    {
      image: Img1,
      name: "Dr. Alok kumar Mohapatra",
      qualification: "MBBS, MD(Medicine), DM(Nephrology)",
      location: "Ashwini Hospital, Aditya Ashwini Hospital",
    },
  ];

  const gallery = [{ image: G1,caption:'' }, { image: G2,caption:'' }, { image: G3,caption:'' }];

  const opdTimings = [
    {
      doctor: "Dr. Alok Kumar Mohapatra",
      schedule: [
        { day: "MON", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
        { day: "TUE", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
        { day: "", time: "After 05:00 PM", centre: "Aditya Ashwini Hospital" },
        { day: "WED", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
        { day: "THU", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
        { day: "", time: "After 05:00 PM", centre: "Aditya Ashwini Hospital" },
        { day: "FRI", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
        { day: "SAT", time: "09:30 AM - 05:00 PM", centre: "Ashwini Hospital" },
      ],
    },
  ];
  return (
    <>
      <Navbar />

      {/* Nephrology Banner */}
      <section className="page-title text-center">
        <div className="bg-layer">
          <img
            src={NephrologyBanner}
            alt="Nephrology Banner"
            title="Nephrology Banner"
          />
        </div>
        <div className="container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/Departments"}>Departments</Link>
              </li>
              <li>Nephrology & Dialysis</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Nephrology Doctors */}
      <div className="my-3">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* opd timings */}
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                <div className="widget-content">
                  <div className="accordion" id="opdTimingsAccordion">
                    {opdTimings.map((opd, index) => (
                      <div className="accordion-item" key={`opd-${index}`}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                          <button
                            className={`accordion-button ${
                              openIndex === index ? "" : "collapsed"
                            }`}
                            type="button"
                            onClick={() => toggleAccordion(index)}
                            aria-expanded={
                              openIndex === index ? "true" : "false"
                            }
                            aria-controls={`collapse${index}`}
                          >
                            {opd.doctor}
                            <span className="toggle-text">
                              {openIndex === index ? "Hide" : "Show"}
                            </span>
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            openIndex === index ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-bs-parent="#opdTimingsAccordion"
                        >
                          <div className="accordion-body">
                            <table className="table table-hover table-light">
                              <thead>
                                <tr>
                                  <th scope="col">DAYS</th>
                                  <th scope="col">TIMINGS</th>
                                  <th scope="col">CENTRE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {opd.schedule.map((schedule, idx) => (
                                  <tr
                                    className="opd-table"
                                    key={`schedule-${index}-${idx}`}
                                  >
                                    <th scope="row">{schedule.day}</th>
                                    <td>{schedule.time}</td>
                                    <td>{schedule.centre}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* gallery */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image,g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                  <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="card-text">
                    <p>
                      The nephrology unit is managed by highly qualified and
                      experienced senior consultants in Nephrology. The
                      department functions as a tertiary care unit, complimented
                      with urological services.
                    </p>

                    <p>
                      The department provides specialized care for the patients
                      with UTI, acute and chronic renal failure, hypertension,
                      glomerulo- nephritis, check up of post kidney transplant
                      patients and also extends support to other departments in
                      the management of wide ranging renal problems.
                    </p>

                    <p>
                      Our new renal dialysis unit is well equipped with 8 nos.
                      of Machine including ARRT Plus to provide the renal
                      replacement therapy for patients suffering from end stage
                      of renal disease and acute kidney injury on a regular
                      basis. With the support of dedicated staffs and efficient
                      doctors, the department is committed to deliver the
                      specialized services with care and compassion.
                    </p>

                    <p>
                      In ICU cytosorb therapy is given to patients with
                      septicaemia. Dialysis by ARRT plus machine in low blood
                      pressure patients are also done in Ashwini Hospital.
                      Haemodialysis Services are provided to Hepatitis B and C
                      positive patients as well by our team of experienced
                      consultants.
                    </p>
                    <p>
                      We are well trained and equipped for the treatments of :-
                    </p>
                    <ul className="ul_list">
                      <li>Outpatient clinics</li>
                      <li>Care of inpatients with kidney disorders </li>
                      <li>Consultation services to other departments</li>
                      <li>Dialysis service</li>
                      <li>Pediatric Nephrology Service</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="sec-title">
                    <h1>Services available</h1>
                  </div>

                  <div className="card-text">
                    <ul className="ul_list">
                      <li>USG guided kidney biopsy</li>
                      <li>Renal Function Test (RFT)</li>
                      <li>Urine ACR</li>
                      <li>Serum Electrolyte & Urine Electrolyte Test</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nephrology;
