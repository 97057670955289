import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import SEO from "../../SEO/SEO";
import Img1 from "../../../assets/doctors/kanakaya_reddy.png";
import Img2 from "../../../assets/doctors/narayan_behera.png";
import Img4 from "../../../assets/doctors/sourav_mondal.png";
import G1 from "../../../assets/department photos/ortho1.png";
import G2 from "../../../assets/department photos/ortho2.png";
import G7 from "../../../assets/department photos/ortho7.png";
import G8 from "../../../assets/department photos/ortho8.jpg";
import G9 from "../../../assets/department photos/ortho9.jpg";
import G10 from "../../../assets/department photos/ortho10.jpg";
import G11 from "../../../assets/department photos/ortho11.jpg";

function ATCOrthopedic() {
  const [openIndex, setOpenIndex] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
  };
  const openModal = (image, caption) => {
    setCurrentImage(image);
    setCurrentCaption(caption);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };
  const slides = [
    {
      image: Img1,
      name: "Dr. B Kanakaya Reddy",
      qualification: "M.S(Ortho), DNB Orthopedics, Senior Faculty Orthopedics",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img2,
      name: "Dr. Satya Narayan Behera",
      qualification:
        "M.S(Ortho), Fellow in Arthroplasty, Senior Faculty Orthopedics",
      location: "Ashwini Trauma Centre",
    },
    {
      image: Img4,
      name: "Dr. Sourav Mondal",
      qualification: "M.S(Ortho), Junior Consultant Orthopedics",
      location: "Ashwini Trauma Centre",
    },
  ];
  const gallery = [
    { image: G1, caption: "Dr. B.K Reddy at OPD" },
    { image: G11, caption: "Dr. B.K Reddy and Team" },
    { image: G2, caption: "Ward Round" },
    { image: G7, caption: "Seminar Teaching" },
    { image: G8, caption: "Robotic Knee Replacement Surgery" },
    { image: G9, caption: "Robotic Knee Replacement Surgery" },
    { image: G10, caption: "Robotic Knee Replacement Surgery" },
  ];

  const opdTimings = [
    {
      doctor: "Dr. B Kanakaya Reddy",
      schedule: [
        {
          day: "TUE",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "THU",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "SAT",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
      ],
    },
    {
      doctor: "Dr. Satya Narayan Behera",
      schedule: [
        {
          day: "MON",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "WED",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
        {
          day: "FRI",
          time: "10:00 AM - 02:00 PM",
          centre: "Ashwini Trauma Centre",
        },
      ],
    },
  ];

  return (
    <>
      <NavbarLocation />
      <SEO
        title="Orthopedics, Trauma & Rehabilitation Department | Ashwini Trauma Centre, Cuttack"
        description="Ashwini Trauma Centre's Orthopedics, Trauma & Rehabilitation Department in Odisha offers expert care for fractures, joint replacement, sports injuries, spinal trauma, and post-surgical recovery with a multidisciplinary approach."
        keywords="Orthopedics Cuttack, Trauma Care Hospital India, Joint Replacement Odisha, Sports Injury Treatment, Spine Surgery Hospital, Rehabilitation Services India, Fracture Management Odisha, Orthopedic Hospital in India, Ashwini Hospital Orthopedics, Ortho Trauma Care Odisha"
      />
      {/* Orthopedics Doctors */}
      <div className="doctors-box">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 hero-section">
            {/* opd timings */}
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                <div className="widget-content">
                  {opdTimings.map((opd, index) => (
                    <div className="accordion-item" key={`opd-${index}`}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            openIndex === index ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndex === index ? "true" : "false"}
                          aria-controls={`collapse${index}`}
                        >
                          {opd.doctor}
                          <span className="toggle-text">
                            {openIndex === index ? "Hide" : "Show"}
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          openIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#opdTimingsAccordion"
                      >
                        <div className="accordion-body">
                          <table className="table table-hover table-light">
                            <thead>
                              <tr>
                                <th scope="col">DAYS</th>
                                <th scope="col">TIMINGS</th>
                                <th scope="col">CENTRE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {opd.schedule.map((schedule, idx) => (
                                <tr
                                  className="opd-table"
                                  key={`schedule-${index}-${idx}`}
                                >
                                  <th scope="row">{schedule.day}</th>
                                  <td>{schedule.time}</td>
                                  <td>{schedule.centre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* gallery photos */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image, g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="section-1">
                    <h3 className="sec-title">
                      Orthopaedics Department at Ashwini Trauma Centre, Cuttack
                    </h3>
                    <h5 className="fs-6 fw-bold text-primary">
                      About the Department
                    </h5>
                    <p>
                      <strong>Ashwini Trauma Centre</strong> have
                      firmly established as the leading trauma care
                      facilities in Odisha and the neighboring states. We offer
                      a comprehensive trauma care program that spans from
                      initial resuscitation and stabilization to the complete
                      rehabilitation of poly-trauma patients.
                    </p>
                    <p>
                      Our expert team comprises{" "}
                      <strong>
                        Orthopedic Surgeons, Spine Surgeons, Plastic Surgeons,
                         Vascular Surgeons, Maxillofacial
                        Surgeons, Critical Care Physicians and Rehabilitation
                        Therapists
                      </strong>
                      . Backed by a robust infrastructure, round-the-clock
                      emergency services, and dedicated support staff, we strive
                      to save as many lives and limbs as possible.
                    </p>
                    <p>
                      In recognition of our contribution, the{" "}
                      <strong>Departments of Health and Transport</strong> have
                      partnered with us to offer free treatment to road traffic
                      accident victims during the first 48 hours.
                    </p>
                    <h4 className="sec-title">
                      Why Choose Ashwini Trauma Centre for Orthopedic in Odisha?
                    </h4>
                    <ul className="custom-style">
                      <li>
                        <strong>Comprehensive Multidisciplinary Care:</strong>{" "}
                        Integrated approach with expert surgeons and
                        rehabilitation specialists
                      </li>

                      <li>
                        <strong>24x7 Emergency & Trauma Services:</strong>{" "}
                        Always ready with dedicated trauma teams and rapid
                        response systems
                      </li>

                      <li>
                        <strong>Advanced Surgical Techniques:</strong> Minimally
                        invasive procedures and cutting-edge technology
                      </li>

                      <li>
                        <strong>Robotic Knee Replacement:</strong>{" "}
                        Precision-driven robotic surgeries for optimal outcomes
                      </li>

                      <li>
                        <strong>High-Volume Expertise:</strong> Thousands of
                        successful procedures annually
                      </li>
                      <li>
                        <strong>State-of-the-art Operation Theatres:</strong>{" "}
                        Laminar airflow, modular OTs, and real-time imaging
                      </li>
                      <li>
                        <strong>Free First 48-Hour RTA Care:</strong>{" "}
                        Government-supported emergency care for accident victims
                      </li>
                      <li>
                        <strong>Seamless Rehabilitation & Follow-up:</strong>{" "}
                        Holistic recovery plans for improved long-term function
                      </li>
                      <li>
                        <strong>
                          {" "}
                          Recognized DNB Orthopaedics Training Centre:
                        </strong>{" "}
                        A premier institute offering hands-on surgical training,
                        structured academics, and multispecialty exposure for
                        postgraduate residents
                      </li>
                    </ul>
                    <h4 className="sec-title">Expertise & Services</h4>
                    <ul className="list-unstyled ">
                      <li>
                        <strong className="text-primary">
                          Trauma & Complex Fracture Management
                        </strong>
                        &#9989;
                        <p className="mb-2">We routinely manage:</p>
                        <ul>
                          <li>
                            <strong>Complex pelvic-acetabular fractures</strong>{" "}
                            (anterior column, bi-columnar, posterior wall,
                            transverse types)
                          </li>
                          <li>
                            Fractures with{" "}
                            <strong>vascular or nerve injuries</strong>
                          </li>
                          <li>
                            Neglected and complicated{" "}
                            <strong>non-union fractures</strong> using Ilizarov
                            techniques
                          </li>
                          <li>
                            <strong>Free vascularised fibula transfers</strong>
                          </li>
                          <li>
                            <strong>
                              Floating knee injuries, complex intra-articular
                              fractures
                            </strong>
                          </li>
                          <li>
                            Fractures around the{" "}
                            <strong>hip, shoulder, elbow, and hand</strong>
                          </li>
                          <li>
                            <strong>
                              Minimally Invasive Plate Osteosynthesis (MIPPO)
                            </strong>{" "}
                            for reduced scarring
                          </li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary ">
                          Reconstructive Orthopaedic Surgery
                        </strong>
                        &#9989;
                        <p className="mb-2">We offer advanced procedures in:</p>
                        <ul>
                          <li>
                            <strong>Deformity correction:</strong> Genu
                            varum/valgum, Coxa vara/valga, Cubitus varus/valgus
                          </li>
                          <li>Limb lengthening and contracture releases</li>
                          <li>
                            <strong>Osteotomies</strong> for congenital or
                            childhood conditions like{" "}
                            <strong>DDH and Perthes disease</strong>
                          </li>
                          <li>
                            <strong>CTEV correction</strong> in both children
                            and adults
                          </li>
                          <li>
                            Correction of cavovarus deformity and{" "}
                            <strong>flat foot</strong>
                          </li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary">
                          Shoulder Arthroscopy
                        </strong>
                        &#9989;
                        <ul>
                          <li>
                            <strong>Arthroscopic Rotator Cuff Repair</strong>{" "}
                            using modified Double Row Suture Bridge technique
                          </li>
                          <li>
                            <strong>
                              Arthroscopic Bankart and Latarjet surgery
                            </strong>{" "}
                            for recurrent dislocations
                          </li>
                          <li>
                            <strong>
                              Arthroscopic AC joint reconstruction, SLAP repair,
                              and capsule release
                            </strong>
                          </li>
                          <li>
                            Subacromial decompression and AC joint resection
                          </li>
                          <li>
                            Decompression of spinoglenoid cysts with
                            suprascapular nerve involvement
                          </li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary">
                          Knee Arthroscopy
                        </strong>
                        &#9989;
                        <ul>
                          <li>
                            <strong>ACL & PCL reconstruction</strong> (single
                            and double bundle techniques)
                          </li>
                          <li>
                            Meniscus repair using all-inside, inside-out, and
                            outside-in methods
                          </li>
                          <li>
                            Management of complex multiple{" "}
                            <strong>ligament injuries (LCL, MCL)</strong>
                          </li>
                          <li>Arthroscopic popliteus reconstruction</li>
                          <li>
                            <strong>MPFL reconstruction</strong> for recurrent
                            patellar dislocations
                          </li>
                          <li>
                            Osteochondral repair using OATS and mosaicplasty
                          </li>
                          <li>Corrective osteotomies around the knee</li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary">Spine Surgery</strong>
                        &#9989;
                        <p className="mb-2">
                          We offer both{" "}
                          <strong>
                            open and minimally invasive surgical (MIS)
                          </strong>{" "}
                          solutions for spinal conditions:
                        </p>
                        <ul>
                          <li>
                            <strong>MIS TLIF</strong> surgery for
                            spondylolisthesis and degenerative spine disease
                          </li>
                          <li>
                            Microscopic discectomy, hemi-laminectomy, and
                            laminectomy
                          </li>
                          <li>
                            <strong>Spinal fracture fixation</strong>,
                            particularly in the dorso-lumbar region
                          </li>
                          <li>Balloon kyphoplasty and vertebroplasty</li>
                        </ul>
                      </li>
                      <li className="mt-2">
                        <strong className="text-primary">
                          Joint Replacement Surgery
                        </strong>
                        &#9989;
                        <p className="mb-2">
                          Conducted in advanced modular operation theatres:
                        </p>
                        <ul>
                          <li>
                            <strong>Robotic Total Knee Replacement</strong> –
                            Enhanced precision, better alignment, and faster
                            recovery using state-of-the-art robotic technology
                          </li>
                          <li>
                            <strong>Total Knee Replacement (TKR)</strong>
                          </li>
                          <li>
                            High Tibial Osteotomy (<strong>HTO</strong>) for
                            Varus/Valgus deformities
                          </li>
                          <li>
                            <strong>
                              Total Hip Replacement (THR) & Revision THR
                            </strong>
                          </li>
                          <li>
                            Uni-condylar Knee Replacement (<strong>UKR</strong>)
                          </li>
                          <li>
                            <strong>Revision Total Knee Replacement</strong>
                          </li>
                          <li>
                            <strong>Complex Primary Hip Replacement</strong>
                          </li>
                          <li>
                            Total Shoulder & Reverse{" "}
                            <strong>Shoulder Replacement</strong>
                          </li>
                          <li>
                            <strong>Total Elbow Replacement</strong>
                          </li>
                          <li>
                            <strong>Radial Head Replacement</strong>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    {/* <div className="row text-center">
                      {[
                        { image: G2, caption: "Reconstructive Surgery" },
                        { image: G3, caption: "Reconstructive Burn Surgery" },
                        { image: G8, caption: "Complex Wound Coverage" },
                      ].map((facility, index) => (
                        <div key={index} className="col-md-4 mb-4 mt-2">
                          <div className="card h-100 shadow-sm">
                            <img
                              src={facility.image}
                              alt={facility.caption}
                              title={facility.caption}
                              className="img-fluid"
                              style={{ height: "200px", objectFit: "fill" }}
                              onClick={() =>
                                openModal(facility.image, facility.caption)
                              }
                            />
                            <div
                              className="p-2"
                              style={{ backgroundColor: "aliceblue" }}
                            >
                              <h5 className="fs-6 text-primary">
                                {facility.caption}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ATCOrthopedic;
